<template>
  <popup-dialog @close="close" v-if="visible">
    <div class="chinese-lock">
      <div class="chinese-lock-logo">
        <img src="~@/assets/chinese_logo.png" />
      </div>
      <div class="chinese-lock-list">
        <div class="chinese-lock-list-cell" v-if="report.fact_price">
          <div class="chinese-lock-list-cell-title">解锁本文</div>
          <div class="chinese-lock-list-cell-price">
            <span class="unicode">￥</span>
            <span class="money">{{ report.fact_price }}</span>
            <span class="unit">
              /篇
            </span>
          </div>
          <div class="chinese-lock-list-cell-describe">
            <div class="chinese-lock-list-cell-describe-underline" v-if="report.fact_price != report.price">
<!--              <span>原价：</span>-->
<!--              <span class="cost_price">{{ report.price }}</span>-->
            </div>
          </div>
          <div class="chinese-lock-list-cell-desc">
            <ul>
              <li>阅读本篇报告全文</li>
            </ul>
          </div>
          <div class="chinese-lock-list-cell-btn" @click="unlock">
            解锁
          </div>
        </div>
        <template v-if="levelList && levelList.length > 0">
          <div class="chinese-lock-list-cell" v-for="(item, index) in levelList" :key="index">
            <div class="chinese-lock-list-cell-title">{{ item.title }}</div>
            <div class="chinese-lock-list-cell-price">
              <span class="unicode">￥</span>
              <span class="money">{{ item.fact_price }}</span>
              <span class="unit">
                /{{ item.unit }}
              </span>
            </div>
            <div class="chinese-lock-list-cell-describe">
              <div class="chinese-lock-list-cell-describe-underline" v-if="item.fact_price != item.price">
                <span>原价：</span>
                <span class="cost_price">{{ item.price }}</span>
              </div>
              <div class="chinese-lock-list-cell-describe-first_price" v-if="item.first_time_price && item.first_time_price != item.fact_price ">
                <span>首次购买价：</span>
                <span class="cost_price">{{item.first_time_price}}</span>
              </div>
            </div>
            <div class="chinese-lock-list-cell-desc">
              <ul>
                <li v-for="(jtem, jndex) in item.tips" :key="jndex">{{ jtem.text }}</li>
              </ul>
            </div>
            <div class="chinese-lock-list-cell-btn" @click="subscribe(item.id)">
              订阅
            </div>
          </div>
        </template>
      </div>
    </div>
    <!-- 选择项目进行支付 -->
    <chinese-project ref="chineseProjectRef"></chinese-project>
  </popup-dialog>
</template>

<script>
import { memberLevelList, initConfig } from "@/api";
import ChineseProject from "../../equity/component/chineseProject";
import PopupDialog from "@/components/popupDialog";
export default {
  name: "ChineseLock",
  components: {
    ChineseProject,
    PopupDialog
  },
  props: {
    // 报告详情
    report: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      visible: false,
      loading: false,
      // 会员服务协议
      serviceAgreement: "",
      userLevel: {},
      levelList: []
    }
  },
  created() {
    this.getMemberLevelList();
    this.getInitConfig();
  },
  methods: {
    open() {
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
    // 获取会员类型列表
    async getMemberLevelList() {
      this.loading = true;
      let res = await memberLevelList({ search: "id:4|status:1", searchFields: "id:=|status:=", orderBy: "id", sortedBy: "asc" });
      if (res && res.code == 200) {
        if (res.data && res.data.list && res.data.list.length > 0) {
          this.userLevel = res.data.list[0];
          this.levelList = this.dataHandle(res.data.list[0])
        }
      }
      this.loading = false;
    },
    // 数据处理
    dataHandle(row) {
      let arr = [];
      if (row.user_level_details && row.user_level_details.length > 0) {
        for (let i = 0; i < row.user_level_details.length; i++) {
          let item = row.user_level_details[i];
          let temps = { title: "", leveId: 0, price: "", fact_price: "", first_time_price: "", unit: "", tips: [], obj: {} };
          if (item.name.indexOf('月') > -1) {
            temps.title = "月度VIP";
            temps.price = item.price;
            temps.fact_price = item.fact_price;
            temps.first_time_price = item.first_time_price;
            temps.unit = "月";
            temps.id = item.id;
            temps.tips = [
              { text: "查阅往期研究报告" },
              { text: "中英双语覆盖" },
              { text: "一键畅听报告，浏览独家视频" },
            ];
            temps.obj = item;
            arr.push(temps);
          } else if (item.name.indexOf('年') > -1) {
            temps.title = "年度VIP";
            temps.price = item.price;
            temps.fact_price = item.fact_price;
            temps.first_time_price = item.first_time_price;
            temps.unit = "年";
            temps.id = item.id;
            temps.tips = [
              { text: "全年100+篇深度研究报告" },
              { text: "中英双语覆盖" },
              { text: "赠阅经济类畅销书2本" },
              { text: "一键畅听报告，浏览独家视频" },
              { text: "会员专享主题活动等" },
            ];
            temps.obj = item;
            arr.push(temps);
          }
        }
      }
      return arr;
    },
    // 获取配置信息
    async getInitConfig() {
      let res = await initConfig();
      if (res && res.code == 200) {
        if (res.data && res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            let item = res.data[i];
            if (item.config_key == "MEMBERSHIP_SERVICE_AGREEMENT") {
              this.serviceAgreement = item.config_value;
              break;
            }
          }
        }
      }
    },
    // 解锁当前文章
    unlock() {
      this.$emit("unlock");
    },
    // 订阅
    subscribe(id) {
      let token = localStorage.getItem("cf40-token");
      if (token) {
        this.$refs.chineseProjectRef.open(this.userLevel, id);
      } else {
        this.$emit("login");
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.chinese-lock {
  &-logo {
    max-width: 220px;
  }
  &-list {
    margin-top: 50px;
    margin-bottom: 30px;
    display: flex;
    &-cell {
      flex: 1;
      position: relative;
      padding: 30px;
      border: 1px solid #cccccc;
      margin-right: 30px;
      &:last-child {
        margin-right: 0;
      }
      &-title {
        text-align: center;
        font-size: 20px;
        font-family: HarmonyOS_Bold;
      }
      &-price {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        .money {
          font-size: 50px;
          font-family: HarmonyOS_Bold;
        }
        .unicode {
          margin-right: 5px;
          margin-top: 13px;
          display: flex;
          align-items: flex-start;
        }
        .unit {
          margin-left: 5px;
          margin-bottom: 12px;
          display: flex;
          align-items: flex-end;
        }
      }
      &-describe {
        height: 38px;
        font-size: 14px;
        text-align: center;
        &-underline {
          color: #7f7f7f;
          text-decoration: line-through;
        }
        &-first_price {
          color: #ed6f33;
          .cost_price {
            font-family: HarmonyOS_Bold;
          }
        }
      }
      &-desc {
        margin: 20px auto;
        max-width: 190px;
        height: 120px;
        font-size: 14px;
        color: #7f7f7f;
        line-height: 22px;
      }
      &-btn {
        cursor: pointer;
        border-radius: 5px;
        padding: 8px 0;
        bottom: 30px;
        font-family: HarmonyOS_Bold;
        color: #ffffff;
        user-select: none;
        text-align: center;
        background-color: #ed6f33;
        &:hover, &:active {
          background-color: #f38a58;
        }
      }
    }
  }
}
/* 针对平板 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .chinese-lock {
    &-list {
      &-cell {
        margin-right: 15px;
        &-price {
          .money {
            font-size: 36px;
          }
        }
        &-desc {
          height: 160px;
        }
      }
    }
  }
}
/* 针对手机 */
@media screen and (max-width: 767px) {
  .chinese-lock {
    &-list {
      flex-direction: column;
      &-cell {
        margin-right: 0;
        margin-top: 30px;
      }
    }
  }
}
</style>