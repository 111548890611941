<template>
  <div class="english-box">
    <div class="english-box-icon" @click="subscribe">
      <i class="el-icon-lock"></i>
    </div>
    <div class="english-box-tip">
      <div>Continue reading your article with a</div>
      <div>
        <img src="~@/assets/research-icon.png" />
        <span>Subscription</span>
      </div>
    </div>
    <div class="english-box-btn" @click="subscribe">Subscribe Now</div>
    <div class="english-box-desc" v-if="!isLogin">
      <span class="english-box-desc-text">Already A Subscriber?</span>
      <div class="english-box-desc-btn" @click="login">Sign In</div>
    </div>
    <english-lock ref="englishLockRef" :report="report" @unlock="unlock" @login="login"></english-lock>
  </div>
</template>

<script>
import EnglishLock from "./englishLock";
export default {
  name: "EnglishBox",
  components: {
    EnglishLock
  },
  props: {
    // 报告详情
    report: {
      type: Object,
      default() {
        return {};
      }
    },
  },
  data() {
    return {
      isLogin: false,
    }
  },
  created() {
    let token = localStorage.getItem("cf40-token");
    if (token) {
      this.isLogin = true;
    } else  {
      this.isLogin = false;
    }
  },
  methods: {
    // 登录
    login() {
      this.$emit("login");
    },
    // 解锁当前文章
    unlock() {
      this.$emit("unlock");
    },
    // 订阅
    subscribe() {
      this.$refs.englishLockRef.open();
    }
  }
}
</script>
<style lang="scss" scoped>
.english-box {
  &-icon {
    cursor: pointer;
    text-align: center;
    font-size: 80px;
    color: #ed6f33;
  }
  &-tip {
    font-size: 38px;
    text-align: center;
    img {
      height: 26px;
      margin-right: 20px;
    }
  }
  &-btn {
    margin: 50px auto auto;
    cursor: pointer;
    user-select: none;
    padding: 20px 20px;
    color: #ffffff;
    font-size: 38px;
    font-family: Georgia_Bold;
    background-color: #ed6f33;
    display: table;
    &:hover {
      background-color: #f58652;
    }
  }
  &-desc {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
    &-text {
      margin-right: 15px;
    }
    &-btn {
      cursor: pointer;
      user-select: none;
      padding: 5px 20px;
      color: #ffffff;
      background-color: #747474;
      &:hover {
        background-color: #898686;
      }
    }
  }
}
/* 针对手机 */
@media screen and (max-width: 767px) {
  .english-box {
    &-icon {
      font-size: 60px;
    }
    &-tip {
      line-height: 32px;
      font-size: 22px;
      text-align: center;
      img {
        height: 16px;
        margin-right: 20px;
      }
    }
    &-btn {
      margin-top: 30px;
      font-size: 28px;
    }
    &-desc {
      margin-top: 20px;
      margin-bottom: 50px;
      font-size: 20px;
      &-text {
        margin-right: 15px;
      }
    }
  }
}
</style>